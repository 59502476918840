

















































import { Config } from 'client-website-ts-library/services';
import { Context } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Embeddable from './Common/Embeddable.vue';
import Form from './Forms/Form.vue';

@Component({
  components: {
    Form,
    Embeddable,
  },
})
export default class SiteMasthead extends Vue {
  private formCtx: Context | null = null;

  private eb: EmbeddedContent = {
    Type: EmbeddedContentType.Youtube,
    EmbedId: '1oL-8iAp09U',
  };

  mounted() {
    this.formCtx = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.Office,
          Id: Config.Website.Settings!.WebsiteId,
        },
      ],
    };
  }
}
